import './footer.css';
import React from 'react'
import logo from "../../assets/images/sfd_logo.png";


const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className='footer-logo-contariner'>
        </div>
        <div className="footer-links-container">
          <div className="footer-links">
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;